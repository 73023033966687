var yearCache = null;

export default httpClient => ({

    /* Retorna todos los nodos (Course_Section y Academic_Course_Group) en los que la persona es profesor */
    async getYears() {
        if (yearCache === null) {
            yearCache = httpClient.get('/v3/years');
        }
        return yearCache;
    }

})